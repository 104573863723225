import React from 'react';
import PrivatePage from '../../common/containers/PrivatePage';
import Content from '../../common/containers/Content';
import ProfileForm from './ProfileForm';
import { withNamespaces } from 'react-i18next';
import Config from '../../config';
import AccountDeleteInfo from '../AccountDeleteInfo';

class ProfilePage extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { i18n } = this.props;
    return (
      <PrivatePage title={i18n.t('Minha conta')}>
        <Content>
          <div className="col-md-8 col-md-offset-2">
            <AccountDeleteInfo />
            <a className="btn btn-default btn-sm" href={`${Config.djangoHost}accounts/password/change/`}>
              {i18n.t('Alterar senha')}
            </a>{' '}
            <a className="btn btn-default btn-sm" href={`${Config.djangoHost}pfl/delete`}>
              {i18n.t('Remover conta')}
            </a>
            <br />
            <br />
            <div className="panel panel-border-color panel-border-color-primary">
              <div className="panel-heading">{i18n.t('Perfil')}</div>
              <div className="panel-body">
                <ProfileForm />
              </div>
            </div>
          </div>
        </Content>
      </PrivatePage>
    );
  }
}

export default withNamespaces()(ProfilePage);
