import React, {Component} from 'react';
import Config from "../config";
import Ajax from "../common/ajax";
import Alert from "../common/components/Alert";
import {withNamespaces} from "react-i18next";

class AccountDeleteInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            profile: null,
        }
    }


    componentDidMount() {
        this.fetchProfile();
    }

    fetchProfile() {
        const url = `${Config.apiHost}profiles/current/`;
        Ajax.get(url, 'mark_to_delete').done(profile => {
            this.setState(state => ({...state, profile}));
        })
    }

    render() {
        const {i18n} = this.props;
        if (this.state.profile && this.state.profile.mark_to_delete) {
            return (
                <Alert type="warning">
                    <p>
                        {i18n.t('Sua conta está marcada para ser removida nas próximas 24hs.')}
                        {' '}
                        <a href={`${Config.djangoHost}pfl/delete`}>{i18n.t('Clique aqui para cancelar')}</a>.
                    </p>
                </Alert>
            );
        }
        return null;
    }
}

export default withNamespaces()(AccountDeleteInfo);